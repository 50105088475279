<template>
  <div>
    <button
      class="absolute left-0 mt-2 hidden px-4 leading-7 text-primary sm:inline-block"
      @click="sectorCodesOpen = true"
    >
      <span class="smooth-transition font-medium underline hover:text-primary-light">Zoeken op sectorcode</span>
      <span class="text-xs"> (STABU, RAW)</span>
    </button>
  </div>
</template>

<script setup lang="ts">
const sectorCodesOpen = useState(OPEN_SECTOR_CODES, () => false);
</script>
