<template>
  <div
    class="expander"
    :class="{
      'expander--expanded': open,
      'overflow-hidden': !overflow,
    }"
  >
    <div class="expander__content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  open?: boolean
}>();

const overflow = ref(false);

watch(() => props.open, (value) => {
  if (value) {
    setTimeout(() => {
      overflow.value = true;
    }, 300);
  } else {
    overflow.value = false;
  }
}, {
  immediate: true,
});
</script>

<style scoped>
.expander {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;
}

.expander__content {
  min-height: 0;
  transition: visibility 1s;
  visibility: hidden;
}

.expander--expanded {
  grid-template-rows: 1fr;
}

.expander--expanded .expander__content {
  visibility: visible;
}
</style>
