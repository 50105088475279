<template>
  <div class="inset-x-0 top-0 z-10">
    <div class="z-10 bg-background">
      <div
        class="relative mx-auto flex items-center justify-between px-4 py-2 text-small lg:container lg:justify-center lg:py-0"
      >
        <div class="inline-block w-full sm:mr-4 sm:flex md:w-1/12">
          <nuxt-link
            :to="{name: 'index'}"
          >
            <img
              src="~/assets/img/logo.svg"
              alt="Insert Logo"
              class="w-20 object-cover sm:w-32 3xl:w-24"
            >
          </nuxt-link>
        </div>
        <div class="flex gap-x-2">
          <nuxt-link
            :to="{name: 'dashboard-favorieten'}"
            class="z-20 block text-xl lg:hidden"
          >
            <font-awesome-icon
              :icon="['far', 'heart']"
              size="sm"
              class="text-primary"
            />
          </nuxt-link>
          <nuxt-link
            :to="{name: 'dashboard'}"
            class="z-20 block text-xl lg:hidden"
          >
            <font-awesome-icon
              :icon="['fal', 'cog']"
              size="sm"
              class="text-primary"
            />
          </nuxt-link>
        </div>
        <nav
          class="hidden bg-primary-lightest py-3 text-center sm:opacity-100 lg:relative lg:inset-auto lg:flex lg:flex-grow lg:bg-transparent lg:py-0 lg:text-left"
        >
          <div
            class="relative my-1 flex w-full justify-center text-sm text-primary sm:leading-7 lg:w-auto lg:flex-grow lg:items-center lg:justify-start lg:text-base"
          >
            <ul class="flex items-center">
              <li>
                <a
                  href="https://insert.nl"
                  target="_blank"
                  class="smooth-transition mx-3 border-b-2 border-solid border-transparent text-small font-semibold hover:border-primary hover:no-underline sm:mx-2 lg:mx-5 lg:ml-6"
                >
                  Over Insert
                </a>
              </li>
              <li>
                <nuxt-link
                  :to="{name: 'aanbieders'}"
                  class="smooth-transition mx-3 border-b-2 border-solid border-transparent text-small font-semibold hover:border-primary hover:no-underline sm:mx-2 lg:mx-5 lg:ml-6"
                >
                  Aanbieders
                </nuxt-link>
              </li>
            </ul>
          </div>
          <ul
            class="mr-3 inline-flex items-center justify-center rounded-md bg-white text-sm text-primary sm:leading-7 md:my-5 md:flex md:justify-start md:pl-5 lg:text-base"
          >
            <li class="mx-2 text-small font-semibold">
              <nuxt-link
                v-if="isLoggedIn && mainRoute === loggedInRoute"
                :to="{name: 'index'}"
                class="smooth-transition flex items-center hover:text-primary-light hover:no-underline"
              >
                <font-awesome-icon
                  :icon="['fal', 'tachometer-alt']"
                  class="mr-1"
                />
                <span class="block">
                  Marktplaats
                </span>
              </nuxt-link>
              <nuxt-link
                v-else-if="isLoggedIn"
                :to="{name: isLoggedIn ? loggedInRoute : 'inloggen'}"
                class="smooth-transition flex items-center hover:text-primary-light hover:no-underline"
              >
                <font-awesome-icon
                  :icon="['fal', 'tachometer-alt']"
                  class="mr-1"
                />
                <span class="block">
                  {{ isProjectManager ? 'Dashboard' : 'Profiel' }}
                </span>
              </nuxt-link>
            </li>
            <li class="mx-2 text-small font-semibold">
              <nuxt-link
                :to="{name: 'dashboard-favorieten'}"
                class="smooth-transition flex items-center hover:text-primary-light hover:no-underline"
              >
                <font-awesome-icon
                  :icon="['far', 'heart']"
                  class="mr-1"
                />
                <span class="block">
                  Favorieten
                </span>
              </nuxt-link>
            </li>
            <li class="mx-2 text-small font-semibold">
              <nuxt-link
                :to="{name: 'help'}"
                class="smooth-transition flex items-center hover:text-primary-light hover:no-underline"
              >
                <font-awesome-icon
                  :icon="['far', 'info-circle']"
                  class="mr-1"
                />
                <span class="block">
                  Help
                </span>
              </nuxt-link>
            </li>
            <li class="mx-2 text-small font-semibold">
              <nuxt-link
                v-if="!isLoggedIn"
                :to="{name: 'inloggen'}"
                class="smooth-transition flex items-center hover:text-primary-light hover:no-underline"
              >
                <font-awesome-icon
                  :icon="['far', 'sign-in']"
                  class="mr-1"
                />
                <span class="block">
                  Inloggen
                </span>
              </nuxt-link>
              <button
                v-else
                class="smooth-transition mx-2 flex items-center text-small font-semibold hover:text-primary-light"
                @click="logout"
              >
                <font-awesome-icon
                  :icon="['far', 'sign-in']"
                  class="mr-1"
                />
                <span class="block">
                  Uitloggen
                </span>
              </button>
            </li>
            <li class="ml-2 rounded-r-md bg-primary-lighter px-6 py-1 text-lg font-semibold">
              <nuxt-link
                :to="{name: 'dashboard-materialen-nieuw'}"
                class="smooth-transition space-between justify-content flex items-center gap-2 hover:text-primary-light hover:no-underline"
              >
                <font-awesome-icon
                  :icon="['fal', 'plus']"
                />
                Materiaal plaatsen
              </nuxt-link>
            </li>
          </ul>
          <div class="flex items-center">
            <button
              class="btn btn-secondary items-center"
              @click="$emit('event:search')"
            >
              <font-awesome-icon
                :icon="['fas', 'search']"
                size="sm"
                class="mr-2 text-white"
              />
              Zoeken
            </button>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    const {isProjectManager} = useOrganisationAccess();
    const {organisationId: selectedOrganisationId} = useSelectedOrganisation();
    const {signOut} = useAuth();
    const user = useSofieUserWhenAuthenticated();

    return {
      isProjectManager,
      selectedOrganisationId,
      signOut,
      user,
    };
  },
  data() {
    return {
      query: this.$route.query && this.$route.query.query,
    };
  },
  computed: {
    mainRoute() {
      return this.$route.name;
    },
    isLoggedIn() {
      return !!this.user;
    },
    loggedInRoute() {
      if (!this.isLoggedIn) {
        return null;
      }

      return this.isProjectManager ? 'dashboard' : 'dashboard-profiel';
    },
  },
  methods: {
    doSearch() {
      if (!this.query) {
        this.$refs.searchText.focus();

        return;
      }

      this.$router.push({name: 'materialen', query: {query: this.query}});
    },
    async logout() {
      await this.signOut();
      await this.$router.push({name: 'inloggen'});
      this.selectedOrganisationId = undefined;
    },
  },
};
</script>
