<template>
  <UiModal
    v-model:open="active"
    title="Zoeken op sectorcode"
    size="small"
  >
    <div
      ref="labelHolder"
      class="relative mb-5 grid grid-cols-2 rounded-full border border-black text-center"
    >
      <p
        ref="code-stabu"
        class="cursor-pointer px-6 py-1"
        @click="changeActiveSector('stabu')"
      >
        STABU
      </p>
      <p
        ref="code-raw"
        class="cursor-pointer px-6 py-1"
        @click="changeActiveSector('raw')"
      >
        RAW
      </p>
      <div
        class="pointer-events-none absolute inset-y-0 rounded-full border-2 border-solid border-black transition-all duration-200 ease-in-out"
        :style="`left: ${label.left}px; right: ${label.right}px;`"
      />
    </div>
    <ul
      class="flex flex-col overflow-y-scroll"
      style="max-height: 300px;"
    >
      <!--fix inline style-->
      <li
        v-for="(category) in categoriesForActiveSector"
        :key="category.id"
        class="relative my-2 whitespace-nowrap leading-7"
      >
        <button
          class="font-semibold"
          @click="goToCategorySlug(category.slug)"
        >
          <span class="mr-4">
            {{ category[openCode] }}
          </span>
          {{ category.title }}
        </button>
        <!--<font-awesome-icon
          v-if="subcode1.subCodes"
          :icon="['fal', openSub === (index + '-' + index2) ? 'chevron-up' : 'chevron-down']"
          fixed-width
          class="absolute right-0 top-0"
        />
        <transition name="fade">
          <ul
            v-if="openSub === (index + '-' + index2)"
            :ref="`${index}-${index2}`"
            :data-indexes="(sectorcode.isActive && subcode1.isActive) ? `${index}-${index2}` : false"
            class="leading-7 ml-16 pr-8"
          >
            <li
              v-for="subcode2 in subcode1.subCodes"
              v-show="subcode1.subCodes"
              :key="subcode2.id"
              class="cursor-pointer my-2"
            >
              {{ `${subcode1.code}.${subcode2.code} ${subcode2.title}` }}
            </li>
          </ul>
        </transition>-->
      </li>
    </ul>
    <!--<div class="flex">
      <button
        class="bg-secondary cursor-pointer text-white rounded-full px-6 py-1 leading-7"
        @click="$emit('hide')"
      >
        Opslaan
      </button>
    </div>-->
  </UiModal>
</template>

<script>
export default {
  setup() {
    const active = ref(false);
    const sectorCodesOpen = useState(OPEN_SECTOR_CODES, () => false);
    const {result} = useCategoriesQuery();
    const categories = computed(() => result.value?.categories?.filter(c => c.parent_id === null) ?? []);

    watch(sectorCodesOpen, sectorCodesOpen => active.value = sectorCodesOpen);
    watch(active, active => sectorCodesOpen.value = active);

    return {
      active,
      categories,
      sectorCodesOpen,
    };
  },
  data: () => ({
    openSub: null,
    openCode: null,
    label: {
      left: 0,
      right: 0,
      activeElem: null,
    },
  }),
  computed: {
    categoriesForActiveSector() {
      if (this.openCode) {
        const key = this.openCode.toLowerCase();
        return this.categories.filter(c => c[key] !== null).sort((a, b) => {
          return a[key] - b[key];
        });
      }
      return [];
    },
  },
  watch: {
    active(val) {
      if (val) {
        setTimeout(() => {
          this.changeActiveSector('stabu');
        }, 1);
      }
    },
  },
  mounted() {
    // TODO: This will break when uncommenting because the sectorcodes prop is no more!
    // this.setActiveSector(this.$refs.label0[0]);
    // this.sectorcodes.forEach((sectorcode, index) => {
    //   if (sectorcode.isActive) {
    //     this.openCode = index;
    //     sectorcode.subCodes.forEach((subcode, index2) => {
    //       if (subcode.isActive) {
    //         this.openSub = index + '-' + index2;
    //         setTimeout(() => {
    //           this.setActiveSector(this.label.activeElem);
    //         }, 350);
    //       }
    //     });
    //   }
    // });
  },
  methods: {
    async goToCategorySlug(slug) {
      await this.$router.push(`/materialen/${slug}`);
      this.active = false;
    },
    changeActiveSector(type) {
      const target = this.$refs[`code-${type}`];
      const labelClientRect = this.$refs.labelHolder.getBoundingClientRect();
      const targetClientRect = target.getBoundingClientRect();
      this.openCode = type;

      this.label = {
        left: targetClientRect.left - labelClientRect.left - 1,
        right: labelClientRect.right - targetClientRect.right - 1,
        activeElem: target,
      };
    },
    setActiveSector(elem) {
      this.label.left = elem.getBoundingClientRect().left - this.$refs.labelHolder.getBoundingClientRect().left - 1;
      this.label.right = this.$refs.labelHolder.getBoundingClientRect().right - elem.getBoundingClientRect().right - 1;
      this.label.activeElem = elem;
    },
    openItem(index, index2) {
      this.openSub = index + '-' + index2;
      setTimeout(() => {
        this.setActiveSector(this.label.activeElem);
      }, 350);
    },
    async navigateToCategory(category) {
      await this.$router.push(`/materialen/${category.slug}`);
    },
  },
}
;
</script>
