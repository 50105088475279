<template>
  <div>
    <div class="hidden lg:block">
      <UiExpander
        :open="show"
        class="sm:bg-primary-lighter"
      >
        <div class="container relative mx-auto bg-white px-4 pb-12 pt-3 md:bg-transparent">
          <button class="mb-3 ml-auto flex items-center font-semibold text-secondary hover:text-secondary-light">
            <span
              class="pr-2 underline"
              @click="$emit('event:close')"
            >
              Sluiten
            </span>
            <font-awesome-icon :icon="['fas', 'times']" />
          </button>
          <form
            class="mx-0 flex justify-around rounded-full rounded-tl-none"
            @submit.prevent="submit"
          >
            <form-search-bar-field-wrapper
              class="rounded-l-md"
            >
              <FormKit
                v-model="form.query"
                type="text"
                :floating-label="true"
                outer-class="$reset w-full"
                :inner-class="!form.query ? 'ring-transparent' : ''"
                placeholder=" "
                label="Zoekwoord"
              />
            </form-search-bar-field-wrapper>
            <div class="flex w-4/5">
              <form-search-bar-field-wrapper edge-line>
                <FormKit
                  v-model="form.sector"
                  :disabled="!sectors.length"
                  type="dropdown"
                  label="Sector"
                  :floating-label="true"
                  outer-class="$reset w-full"
                  :inner-class="!form.sector ? 'ring-transparent' : ''"
                  :options="sectors.map(sector => ({
                    label: sector.title,
                    value: sector.title,
                  }))"
                />
              </form-search-bar-field-wrapper>
              <form-search-bar-field-wrapper edge-line>
                <FormKit
                  v-model="form.category"
                  :disabled="!sectors.length"
                  :floating-label="true"
                  label="Categorie"
                  type="dropdown"
                  outer-class="$reset w-full"
                  :inner-class="!form.category ? 'ring-transparent' : ''"
                  :options="categories.map(category => ({
                    label: category.title,
                    value: category.id,
                  }))"
                />
              </form-search-bar-field-wrapper>
              <form-search-bar-field-wrapper edge-line>
                <FormKit
                  v-model="form.postal_code"
                  type="text"
                  :floating-label="true"
                  label="Postcode of plaats"
                  name="postalCode"
                  outer-class="$reset w-full"
                  :inner-class="!form.postal_code ? 'ring-transparent' : ''"
                  @input="postalCodeUpdated"
                />
              </form-search-bar-field-wrapper>
              <form-search-bar-field-wrapper edge-line>
                <FormKit
                  v-model="form.distance"
                  type="dropdown"
                  :floating-label="true"
                  :options="distances.map(distance => ({
                    label: distance.title,
                    value: distance.value,
                  }))"
                  label="Afstand"
                  outer-class="$reset w-full"
                  :inner-class="!form.distance ? 'ring-transparent' : ''"
                  @input="postalCodeUpdated"
                />
              </form-search-bar-field-wrapper>
              <button
                class="smooth-transition flex w-1/5 items-center justify-center rounded-md rounded-l-none bg-secondary text-lg hover:bg-secondary-light"
              >
                <font-awesome-icon
                  :icon="['fas', 'search']"
                  class="mr-2 text-white"
                />
                <span class="font-semibold text-white">
                  Zoek
                </span>
              </button>
            </div>
          </form>
          <sector-codes />
        </div>
      </UiExpander>
    </div>
    <div class="block lg:hidden">
      <div class="container relative mx-auto bg-white p-2">
        <form
          class="mx-2 flex justify-around rounded-full rounded-tl-none sm:mx-0"
          @submit.prevent="submit"
        >
          <div class="w-4/5">
            <FormKit
              v-model="form.query"
              type="search"
              placeholder="Wat zoekt u?"
              inner-class="rounded-r-none"
              outer-class="$reset"
            />
          </div>
          <div class="flex w-1/5">
            <button
              class="smooth-transition w-full rounded-md  rounded-l-none bg-secondary px-3 pl-6 pr-8 ring-1 ring-secondary-light hover:bg-secondary-light sm:text-lg"
            >
              <font-awesome-icon
                :icon="['fas', 'search']"
                class="text-white lg:mr-2 lg:mr-6"
              />
              <span class="hidden font-bold text-white lg:inline">
                Zoek
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import searchOptions from '~/graphql/queries/searchOptions.gql';

export default {
  apollo: {
    searchOptions: {
      query: searchOptions,
    },
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['event:close'],
  setup() {
    const {result} = useSearchOptionsQuery();
    const searchOptions = computed(() => result.value?.searchOptions ?? []);

    return {
      searchOptions,
    };
  },
  data() {
    return {
      form: {
        query: this.$route.query && this.$route.query.query,
        distance: null,
      },
      distances: [
        {value: '*', title: 'Alle afstanden'},
        {value: 5, title: '5 km'},
        {value: 10, title: '10 km'},
        {value: 20, title: '20 km'},
        {value: 50, title: '50 km'},
        {value: 75, title: '75 km'},
        {value: 100, title: '100 km'},
      ],
    };
  },
  computed: {
    categories() {
      const categories = this.searchOptions.find(option => option.title === 'categories');
      if (!categories) {
        return [];
      }
      return categories.values;
    },
    sectors() {
      const sectors = this.searchOptions.find(option => option.title === 'sectoren');
      if (!sectors) {
        return [];
      }
      return sectors.values;
    },
  },
  methods: {
    postalCodeUpdated() {
      if (this.form.postal_code && !this.form.distance) {
        this.form.distance = 5;
      }
    },
    submit() {
      this.$router.push({name: 'materialen', query: {...this.form}});
    },
  },
};
</script>

<style scoped>
:deep(.formkit-label) {
  background-color: #fff !important;
}
</style>

