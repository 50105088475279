<template>
  <div class="sticky top-0 z-20">
    <div>
      <nav-bar @event:search="showSearch = !showSearch" />
      <search-bar
        :show="showSearch"
        @event:close="showSearch = false"
      />
    </div>
    <div class="fixed bottom-0 z-20 w-full bg-primary-lighter lg:hidden">
      <ul class="text-md flex items-end justify-between font-semibold text-primary">
        <li
          v-for="item in menuItems"
          :key="item.title"
          class="w-1/5 pb-2 pt-4"
        >
          <nuxt-link
            v-if="!item.externalLink && !item.loggedIn"
            :to="item.link"
            class="relative mr-3 flex flex-col items-center hover:no-underline"
          >
            <img
              v-if="item.img"
              src="~/assets/img/icon.png"
              class="size-4"
            >
            <font-awesome-icon
              v-if="item.icon"
              :icon="item.icon"
              size="lg"
            />
            <span class="mx-2 mt-1 text-sm">
              {{ item.title }}
            </span>
          </nuxt-link>
          <a
            v-else-if="item.externalLink"
            :href="item.link"
            target="_blank"
            rel="noopener"
            class="relative mr-3 flex flex-col items-center hover:no-underline"
          >
            <img
              v-if="item.img"
              src="~/assets/img/icon.png"
              class="size-5"
              alt=""
            >
            <font-awesome-icon
              v-if="item.icon"
              :icon="item.icon"
              size="lg"
            />
            <span class="mt mx-2 text-sm">
              {{ item.title }}
            </span>
          </a>
          <button
            v-if="item.loggedIn"
            class="relative mr-3 flex flex-col items-center hover:no-underline"
            @click="logout"
          >
            <font-awesome-icon
              v-if="item.icon"
              :icon="item.icon"
              size="lg"
            />
            <span class="mx-2 mt-1 text-sm font-semibold">
              {{ item.title }}
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    large: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {signOut} = useAuth();
    const user = useSofieUserWhenAuthenticated();
    const selectedOrganisationId = useSelectedOrganisation();
    const {isMaterialManager} = useOrganisationAccess();

    return {
      isMaterialManager,
      selectedOrganisationId,
      signOut,
      user,
    };
  },
  data() {
    return {
      showSearch: false,
    };
  },
  computed: {
    mobileMenu() {
      return [
        {
          title: 'Home',
          link: '/',
          icon: ['fal', 'home'],
          always: true,
        },
        {
          title: 'Over',
          link: 'https://insert.nl',
          externalLink: true,
          img: true,
          always: true,
        },
        {
          title: 'Aanbieder',
          link: '/aanbieders',
          icon: ['far', 'fingerprint'],
          always: true,
        },
        {
          title: 'Plaatsen',
          link: '/dashboard/materialen/nieuw',
          icon: ['fal', 'plus'],
          always: true,
          disabled: !this.isMaterialManager,
        },
        {
          title: 'Inloggen',
          link: '/inloggen',
          icon: ['fal', 'sign-in-alt'],
          loggedIn: false,
        },
        {
          title: 'Uitloggen',
          link: '',
          icon: ['fal', 'sign-in-alt'],
          loggedIn: true,
        },
      ];
    },
    menuItems() {
      return this.mobileMenu.filter(item => (item.loggedIn === !!this.user || item.always) && !item.disabled);
    },
  },
  created() {
    this.showSearch = ['index', 'materialen'].includes(this.$route.name);
  },
  methods: {
    logout() {
      this.signOut();
      this.selectedOrganisationId = undefined;
    },
  },
};
</script>
