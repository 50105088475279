<template>
  <CookieControl locale="nl">
    <template #bar>
      <h3>{{ moduleOptions.localeTexts.nl!.bannerTitle }}</h3>
      <p>
        {{ moduleOptions.localeTexts.nl!.bannerDescription }} <a
          href="https://www.insert.nl/privacybeleid/"
          target="_blank"
          class="pt-3 text-secondary"
        >privacy statement</a>
      </p>
    </template>
  </CookieControl>
</template>

<script setup lang="ts">
const {moduleOptions} = useCookieControl();
</script>
