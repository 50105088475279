<template>
  <div
    class="hidden w-1/5 items-center bg-white p-1 py-4 md:inline-flex xl:px-5"
    :class="{'edgeLine': edgeLine}"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    edgeLine: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
